import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link, Alert } from "../components";
import { ValidatedInput, Recaptcha } from "./components";
import { useFormatMessage } from "../i18n";
import {
  getPasswordRecoveryState,
  recoverPassword,
  initRecoverPassword,
} from "../redux/auth-slice";
import cn from "classnames";
import { validateForm, getErrorMsg } from "../services/validation-service";
import styles from "./PasswordRecoveryPage.module.scss";
import sharedStyles from "../shared.module.scss";
import { StatusPage } from "./StatusPage";
import { passwordRecoveryValidation } from "../shared/validation";
import { scrollToFirstValidationError } from "../utils";

const errorMessages = {
  NOT_FOUND: "this_email_does_not_exist",
  SERVER_ERROR: "something_went_wrong",
};

export function PasswordRecoveryPage() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initRecoverPassword());
  }, [dispatch]);

  const recaptchaRef = useRef();
  const [pristine, setPristine] = useState(true);
  const [recaptcha, setRecaptcha] = useState("");
  const [stage, setStage] = useState("password");

  const {
    recoverPasswordFailed,
    recoverPasswordError,
    recoverPasswordInProgress,
  } = useSelector(getPasswordRecoveryState);

  const errorMessage = tr(
    errorMessages[recoverPasswordError || "SERVER_ERROR"],
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: handleSubmit,
  });

  const validationErrors = validateForm(
    { form: formik.values },
    passwordRecoveryValidation,
  );

  function handleRecaptchaChange(value) {
    setRecaptcha(value);
  }

  async function handleSubmit(values) {
    setPristine(false);
    if (validationErrors.length || !recaptcha) {
      scrollToFirstValidationError();
      return;
    }
    const { email } = values;
    const data = await dispatch(recoverPassword({ email, recaptcha }));
    if (data.meta.requestStatus === "fulfilled") {
      setStage("success");
    }
    recaptchaRef.current?.reset();
    setRecaptcha("");
    setPristine(true);
  }

  return (
    <>
      {stage === "success" && (
        <StatusPage
          text={tr(
            "you_will_receive_an_email_soon_please_follow_the_instructions_for_changing_the_password",
          )}
          variant="success"
          buttonTitle={"go_back"}
        />
      )}
      {stage === "password" && (
        <div className={cn(sharedStyles.content, styles.signupPage)}>
          <form onSubmit={formik.handleSubmit} className={sharedStyles.form}>
            <h4 className={cn(sharedStyles.title, sharedStyles.pageTitle)}>
              {tr("i_have_lost_my_password")}
            </h4>
            <h5 className={cn(sharedStyles.subtitle, sharedStyles.mainText)}>
              {tr("give_us_your_email_in_order_to_get_a_new_password")}
            </h5>
            <ValidatedInput
              label={tr("email")}
              id="email-input"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              errors={getErrorMsg(validationErrors, "email")}
              pristine={pristine}
            />
            <Recaptcha
              rref={recaptchaRef}
              onChange={handleRecaptchaChange}
              pristine={pristine}
              recaptcha={recaptcha}
            />
            {recoverPasswordFailed && (
              <Alert className={styles.error} severity="error">
                {errorMessage}
              </Alert>
            )}
            <Button
              className={styles.button}
              type="submit"
              title={tr("recover_password")}
              disabled={recoverPasswordInProgress}
              variant="primary"
              loading={recoverPasswordInProgress}
            />
            <span className={styles.backButton}>
              {`${tr("already_have_an_account")} `}
              <Link to={"/login"}>{tr("already_have_an_account_login")}</Link>
            </span>
          </form>
        </div>
      )}
    </>
  );
}
