import React from "react";
import { Button } from "../components";
import styles from "./StatusPage.module.scss";
import cn from "classnames";
import { useFormatMessage } from "../i18n";
import { useHistory } from "react-router-dom";
import { ReactComponent as Success } from "../assets/icons/check.svg";
import { ReactComponent as Error } from "../assets/icons/exclamation.svg";
import { ReactComponent as Info } from "../assets/icons/info.svg";
import sharedStyles from "../shared.module.scss";
import { useScrollToTop } from "../hooks";

export function StatusPage({ className, variant, text, buttonTitle }) {
  const tr = useFormatMessage();
  const history = useHistory();
  useScrollToTop([]);

  function handleCancelClick() {
    history.push("/");
  }

  const variants = {
    success: <Success />,
    error: <Error />,
    info: <Info />,
  };

  if (!variant) return null;
  return (
    <div className={cn(className, styles.statusPage)}>
      {variants[variant]}
      <p className={cn(styles.text, sharedStyles.mainText)}>{text}</p>
      <Button
        type="button"
        title={tr(buttonTitle)}
        variant="light"
        size="sm"
        className={styles.btn}
        onClick={handleCancelClick}
      />
    </div>
  );
}
