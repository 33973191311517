import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiClient from "../api/idderocloud-api";
import {
  clearStorages,
  getUserFromStorage,
  hasAccessToken,
  revokeToken,
  setTokens,
  setUser,
} from "../services/auth-service";
import { changeLanguage } from "./settings-slice";

export const login = createAsyncThunk(
  "auth/login",
  async ({ user, rememberMe }, { rejectWithValue, dispatch }) => {
    try {
      const tokens = await apiClient.getTokens(user);
      if (!tokens.accessToken) {
        throw new Error("Failed to get token from the backend");
      }
      setTokens(tokens, rememberMe);
      const data = await apiClient.getUserData(user.username);
      setUser(
        { user: data.name, language: data.lang, email: data.username },
        rememberMe
      );
      dispatch(changeLanguage(data.lang));
      return { name: data.name, email: data.username };
    } catch (err) {
      clearStorages();
      return rejectWithValue(err)
    }
  }
);

// this action is handled in other slices, keep it in mind when changing/renaming
export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    const isRevoked = await revokeToken("refresh_token");
    if (!isRevoked) {
      console.error("Failed to revoke token");
    }
  } catch (err) {
    console.error("Failed to revoke token", err);
  } finally {
    clearStorages();
  }
});

export const recoverPassword = createAsyncThunk(
  "auth/recoverPassword",
  async ({ email, recaptcha }, { rejectWithValue }) => {
    try {
      await apiClient.recoverPassword(email, recaptcha);
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (
    { username, password, name, country, lang, recaptcha_response },
    { rejectWithValue }
  ) => {
    try {
      await apiClient.signup({
        username,
        password,
        name,
        country,
        lang,
        recaptcha_response,
      });
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

export const confirmSignUp = createAsyncThunk(
  "auth/confirmSignUp",
  async ({ confirmation_code }, { rejectWithValue }) => {
    try {
      const data = await apiClient.confirmSignUp({
        confirmation_code,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const confirmForgotPassword = createAsyncThunk(
  "auth/confirmForgotPassword",
  async ({ confirmation_code }, { rejectWithValue }) => {
    try {
      const data = await apiClient.confirmForgotPassword({
        confirmation_code,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const confirmDeleteUser = createAsyncThunk(
  "auth/confirmDeleteUser",
  async ({ confirmation_code }, { rejectWithValue }) => {
    try {
      const data = await apiClient.confirmDeleteUser({
        confirmation_code,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async (_, { rejectWithValue }) => {
    try {
      const user = await apiClient.deleteUser();
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

function getLoginInitialState() {
  return {
    loginInProgress: false,
    loginFailed: false,
    invalidCredentials: false,
  };
}

function getSignupInitialState() {
  return {
    signupInProgress: false,
    signupFailed: false,
    signupSuccess: false,
    signupErrors: [],
  };
}

function getRecoverPasswordInitialState() {
  return {
    recoverPasswordInProgress: false,
    recoverPasswordFailed: false,
    recoverPasswordError: null,
  };
}

function getInitialState() {
  return {
    isLoggedIn: hasAccessToken(),

    user: getUserFromStorage().user,
    email: getUserFromStorage().email,

    ...getLoginInitialState(),
    ...getSignupInitialState(),
    ...getRecoverPasswordInitialState(),

    logoutInProgress: false,
  };
}

const initialState = getInitialState();

// reducers
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initRecoverPassword: (state) => {
      Object.assign(state, getRecoverPasswordInitialState());
    },
    initLogin: (state) => {
      Object.assign(state, getLoginInitialState());
    },
    initSignup: (state) => {
      Object.assign(state, getSignupInitialState());
    },
    updateUserName: (state, action) => {
      state.user = action.payload;
    },
    initServerError: (state) => {
      state.signupErrors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loginInProgress = true;
      state.loginFailed = false;
      state.invalidCredentials = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginInProgress = false;
      state.isLoggedIn = true;
      state.user = action.payload.name;
      state.email = action.payload.email;
    });
    builder.addCase(login.rejected, (state, action) => {
      if (
        action.payload?.type === apiClient.SERVER_ERRORS.INVALID_CREDENTIALS
      ) {
        state.invalidCredentials = true;
      }
      state.loginInProgress = false;
      state.loginFailed = true;
    });

    builder.addCase(signup.pending, (state) => {
      state.signupErrors = [];
      state.signupInProgress = true;
      state.signupFailed = false;
    });
    builder.addCase(signup.fulfilled, (state) => {
      state.isRegistered = true;
      state.signupInProgress = false;
      state.signupFailed = false;
    });
    builder.addCase(signup.rejected, (state, action) => {
      if (action.payload.type) {
        state.signupErrors.push(action.payload.type);
      } else {
        state.signupFailed = true;
      }
      state.signupInProgress = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.logoutInProgress = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutInProgress = false;
      state.isLoggedIn = false;
      state.user = null;
      state.email = null;
    });

    builder.addCase(recoverPassword.pending, (state) => {
      state.recoverPasswordInProgress = true;
      state.recoverPasswordFailed = false;
      state.recoverPasswordError = null;
    });
    builder.addCase(recoverPassword.fulfilled, (state) => {
      state.recoverPasswordInProgress = false;
      state.recoverPasswordFailed = false;
    });
    builder.addCase(recoverPassword.rejected, (state, action) => {
      if (action.payload.type) {
        state.recoverPasswordError = action.payload.type;
      }
      state.recoverPasswordInProgress = false;
      state.recoverPasswordFailed = true;
    });
  },
});

export const {
  initRecoverPassword,
  initLogin,
  initSignup,
  updateUserName,
  initServerError,
} = authSlice.actions;

// selectors
export const isUserLoggedIn = (state) => state.auth.isLoggedIn;
export const getUser = ({ auth }) => ({
  userName: auth.user,
  email: auth.email,
});

export const getLoginState = ({ auth }) => ({
  isLoggedIn: auth.isLoggedIn,
  loginInProgress: auth.loginInProgress,
  loginFailed: auth.loginFailed,
  invalidCredentials: auth.invalidCredentials,
});

export const getPasswordRecoveryState = ({ auth }) => ({
  recoverPasswordInProgress: auth.recoverPasswordInProgress,
  recoverPasswordFailed: auth.recoverPasswordFailed,
  recoverPasswordError: auth.recoverPasswordError,
});

export const getSignupErrors = (state) => state.auth.signupErrors;
export const getSignupState = ({ auth }) => ({
  serverErrors: auth.signupErrors,
  signupInProgress: auth.signupInProgress,
  signupFailed: auth.signupFailed,
});

export const authReducer = authSlice.reducer;
